@media @before-breakpoint-one {
  .accessprofilegroups--panel.flextable--panel {
    a.flextable--context-button {
      display: block;
    }
  }
}

@media @breakpoint-one {
  .accessprofilegroups--panel li {
    > * {
      &.accessprofilegroups--option {
        flex-grow: 0;
        flex-basis: 6rem;
        text-align: center;

        a {
          padding: 0 0.35rem;
        }
      }
    }
  }
}
