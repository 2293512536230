// ============ Audit

@media @before-breakpoint-one {
  .groupaudits--panel.flextable--panel {
    a.flextable--context-button {
      display: block;
    }

    div.flextable--item {
      .mixin-flex-direction(row);
      .mixin-flex-wrap(wrap);

      > * {
        display: block;

        &.groupaudits--name {
          min-width: 100%;
        }

        &.groupaudits--approved,
        &.groupaudits--changed,
        &.groupaudits--comment,
        &.groupaudits--removed,
        &.groupaudits--showall {
          padding-top: 0.5rem;
          flex-grow: 1;
        }
      }
    }
  }
}

@media @breakpoint-one {
  .groupaudits--panel div.flextable--item {
    > * {
      &.groupaudits--name {
        flex-grow: 5;
      }

      &.groupaudits--approved,
      &.groupaudits--changed,
      &.groupaudits--comment,
      &.groupaudits--removed {
        flex-grow: 0;
        flex-basis: 3.5rem;
      }

      &.groupaudits--showall {
        flex-grow: 1;
        padding-left: @spacing;
      }
    }
  }
}

.groupaudits--panel {
  .groupaudits--item {
    .mixin-clearfix;
    background-color: @color-neutral-10;
  }

  .flextable--item {
    background-color: white;
  }

  .groupaudits--approved,
  .groupaudits--changed,
  .groupaudits--comment,
  .groupaudits--removed {
    text-align: center;
    padding: 0;
  }

  .groupaudits--approved {
    color: @color-success;
  }

  .groupaudits--changed {
    color: @color-warning;
  }

  .groupaudits--removed {
    color: @color-alert;
  }
}
