@media @before-breakpoint-one {
  .authgroups--panel.flextable--panel {
    a.flextable--context-button {
      display: block;
    }

    li {
      .mixin-flex-direction(row);
      > *.authgroups--icon {
        display: block;
      }
    }
  }
}

.authgroups--panel {
  .authgroups--icon {
    flex-grow: 0;
    flex-basis: 3rem;
    text-align: center;
    padding-right: @spacing;
  }
}
