// ============ Manage access

.access--panel {
  .vertical-filter--anchor {
    top: -12.5rem;
  }
}

.access--hidden-groups {
  border-top: 1px solid @color-neutral-10;
  margin-top: 2px;
}

.access--private-group {
  color: @color-neutral-40;
}

@media @breakpoint-one {
  .access--panel li {
    > * {
      &.access--name {
        flex-grow: 7;
      }

      &.access--type {
        flex-grow: 4;
      }

      &.access--group {
        flex-grow: 5;
      }

      &.access--option {
        flex-grow: 0;
        flex-basis: 6rem;
        text-align: center;

        a {
          padding: 0 0.35rem;
        }
      }

      &.access--edit {
        display: none;
      }
    }
  }
}
