@media @before-breakpoint-one {
  .groupusers--panel.flextable--panel {
    a.flextable--context-button {
      display: block;
    }
  }
}

@media @breakpoint-one {
  .groupusers--panel li {
    > * {
      &.groupusers--name {
        flex-grow: 6;
        max-width: 35rem;
      }

      &.groupusers--nested {
        flex-grow: 0;
        flex-basis: 2rem;
      }

      &.groupusers--date {
        flex-grow: 3;
      }

      &.groupusers--edit {
        display: none;
      }
    }
  }
}

.groupusers--name i {
  padding: 0 0.5rem;
  float: right;
}
