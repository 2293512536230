@media @before-breakpoint-one {
  .auditor--dashboard--panel.flextable--panel {
    a.flextable--context-button {
      display: block;
    }
  }
}

.auditor--dashboard--panel li {
  .table--marker {
    float: left;
  }

  .has-dropdown {
    padding-right: 0.5rem;
  }
}

@media @breakpoint-one {
  .auditor--dashboard--panel li {
    > * {

      &.auditor--dashboard--groupname,
      &.auditor--dashboard--displayname,
      &.auditor--dashboard--username,
      &.auditor--dashboard--directory,
      &.auditor--dashboard--system {
        flex-grow: 10;
      }


      &.auditor--dashboard--classification {
        flex-grow: 10;
        > i {
          float: right;
          display: none;
          padding-right: 0.5rem;
          color: @color-neutral-50;
        }

        &.is-editor {
          cursor: default;
          margin-top: -0.55rem;
          margin-bottom: -0.6rem;
        }
        &:hover:not(.is-editor):not(.is-header) {
          border: 1px solid @color-neutral-50;
          padding-right: 0;
          margin: -1px calc(0.5rem - 1px) -1px -1px;
          border-radius: @border-radius-1;

          > i {
            display: block;
          }
        }
      }

      &.auditor--dashboard--managers,
      &.auditor--dashboard--members {
        flex-grow: 4;
        overflow: visible;
      }

      &.auditor--dashboard--twofactorstatus,
      &.auditor--dashboard--accountstatus,
      &.auditor--dashboard--passwordrotation {
        flex-grow: 8;
      }

      &.auditor--dashboard--auditduedate {
        flex-grow: 8;
        overflow: visible;
      }

      &.auditor--dashboard--lastauditdate,
      &.auditor--dashboard--lastlogindate {
        flex-grow: 8;
      }

      &.auditor--dashboard--vaultsrecords {
        flex-grow: 6;
      }
    }
  }
}
