// =========== Curriculum

div.curriculum {
	position: relative;
	margin-bottom: @spacing * 2;

	&.disabled {
		font-style: italic;
		pointer-events: none;
		opacity: 0.4;
	}
}

div.curriculum--intro {
	margin-bottom: 2px;
	border-radius: 0;
	border-bottom: 0;

	h2 {
		font-size: @font-size-50;
		margin-bottom: 0.1rem;
		word-wrap: word-break;
	}

	small {
		text-transform: uppercase;
		font-size: @font-size-20;
		margin-right: 0.35rem;
	}
}

.curriculum--edit {
	float: right;
	position: relative;
	top: 0.4rem;
	cursor: pointer;
	line-height: 1.8rem;

	i {
		position: relative;
		margin-left: 0.3rem;
		top: -1px;
	}

	&:last-of-type {
		clear: right;
	}
}

.curriculum--files {
	margin-bottom: 2px;
	border: none;
}

.curriculum--options {
	margin-bottom: 2px;
	border: none;
	text-align: right;
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		display: inline-block;
	}

	a {
		padding: 1rem;
		display: block;
	}

	i {
		position: relative;
		top: 0.15rem;
	}
}
