@media @before-breakpoint-one {
  .accessprofileaccounts--panel.flextable--panel {
    a.flextable--context-button {
      display: block;
    }
  }
}

@media @breakpoint-one {
  .accessprofileaccounts--panel li {
    > * {
      &.accessprofileaccounts--name {
        flex-grow: 7;
      }

      &.accessprofileaccounts--attributes {
        flex-grow: 3;
      }

      &.accessprofileaccounts--edit {
        display: none;
      }
    }
  }
}
