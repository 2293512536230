// =========== Navigation

nav.navigation {
  //behaviour as a child
  .mixin-flex(0 0 auto);
  -webkit-box-flex: 0;

  // //behaviour as a parent
  .mixin-display-flex;
  .mixin-flex-wrap(nowrap);
  .mixin-flex-direction(column);
  .mixin-justify-content(flex-start);
  .mixin-align-items(flex-start);
  .mixin-align-content(flex-start);

  background-color: @color-neutral-70;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;

  color: @white;
  font-weight: 400;
  z-index: 500;
  width: @navigation-width;
  -webkit-font-smoothing: antialiased;

  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1); //iOS 6.1 en Android 4.3
  transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  .mixin-transform(translateX(-@navigation-width));

  @media @breakpoint-two {
    position: fixed;
    height: 100%;
    -webkit-transition: none;
    transition: none;
    .mixin-transform(translateX(0));
    z-index: 0;
    .mixin-order(1);
  }

  &:after {
    display: block;
    content: " ";
    position: absolute;
    z-index: 8;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: @navigation-width;
    background-color: @color-neutral-70;
  }
}

// If mobile navigation is open, lock the body content
body.is-locked {
  overflow: hidden;
  position: fixed;
  .mixin-transform(translateX(@navigation-width));
  -webkit-backface-visibility: hidden;

  @media @breakpoint-two {
    overflow: visible;
    position: static;
    -webkit-transition: none;
    transition: none;
    .mixin-transform(none);
  }
}
